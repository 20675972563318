
.left-header-right {
  display: flex;
  align-items: center;
}
.ant-layout {
  padding: 10px;
}
.ant-row.header_wrapper {
  background: #00d1b2;
  padding: 11px 5px;
  color: #fff;
}

.ant-row.search_bar_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.ant-col.left-search-input {
  margin-right: 11px;
}
.img_warp {
  width: 50%;
}
.content_wrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: baseline;
  margin-left: 3px;
  position: relative;
}
.ant-card-grid.wraper {
  display: flex;
  height: 215px;
  width: 19% !important;
  text-align: center;
  border: 1px solid #c1c0c0;
  margin: 5px 5px;
}
.img_warp img {
  width: 50%;
  height: 100%;
}
.content {
  display: block;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
span.star_icon {
  position: absolute;
  right: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 9px 10px !important;
}
.golden {
  color: gold;
}
a {
  color: #fff;
  font-size: 18px;
}
.ant-col.left-header-text {
  font-size: 18px;
}
.ant-layout-content {
  -ms-flex: auto;
  flex: auto;
  min-height: 550px;
}
.no_result {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
@media screen and (max-width: 700px) {
  .ant-card-grid.wraper {
    display: flex;
    height: 215px;
    width: 100% !important;
    text-align: center;
    border: 1px solid #c1c0c0;
    margin: 3px 5px;
 }
 .ant-row.header_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.ant-col.left-header-right.ant-col-xl-4 {
  display: flex;
  justify-content: space-between;
  width: 58%;
}
.ant-col.right-btn-wrap {
  margin-top: 10px;
}
}